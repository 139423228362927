html {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400; //medium
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  margin: 0
}

// border box reset
html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  background-color: #0080C9;
  color: white;
}

.content-margin {
  margin: 0 32px;
  @include md() {
    margin: 0 120px;
  }
}

a {
  text-decoration: none;
  color: #ffbd45;
}

a:hover {
  text-decoration: underline;
}
