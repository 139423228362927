//bulma framework breakpoints
$md-break: "screen and (min-width: 1024px)";
$lg-break: "screen and (min-width: 1216px)";
@mixin responsive($media) {
  @if $media == md {
    @media #{$md-break} {
      @content;
    }
  }
  @if $media == lg {
    @media #{$lg-break} {
      @content;
    }
  }
}
@mixin md() {
  @include responsive(md) {
    @content;
  }
}

.hide-md {
  @include responsive(md) {
    display: none;
  }
}

.show-md {
  display: none;
  @include md() {
    display: inherit;
  }
}
//flex-grid
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row,
section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    flex-basis: 100%;
    @include md() {
      flex: 1;
    }
  }
}
@include md() {
  //add classes for specific column sizes as need
  ._20 {
    flex: 2.0;
  }

  ._33 {
    flex: 3.33;
  }

  ._40 {
    flex: 4.0;
  }

  ._50 {
    // this is a 50% width column
    flex: 5.0;
  }

  ._66 {
    flex: 6.66;
  }
}

// when needed, put this on <main> element to constrain content size
.container {
  flex: 1 1 auto;
  max-width: 1216px; //lg breakpoint
  margin: 0 5%;
  // when max width + both side margins is reached use auto growing margins
  @media screen and (min-width: 1351px) {
    margin: 0 auto;
  }
}
