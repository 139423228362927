.banner {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  img {
    max-width: 55%;
  }

  div {
    text-align: center;
    h1 {
      font-size: 4rem;
    }
  }

  .flex-row {
    width: 100%;
    margin: 4rem;
    justify-content: space-around;
  }
}
