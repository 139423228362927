$height: 40px;
$heightMd: 60px;
$fontSize: 1em;
$fontSizeMd: 1.125rem;
$borderSize: 2px;
$borderSizeMd: 3px;
$padding: calc((#{$height} - #{$fontSize} - (#{$borderSize} * 2)) / 2);
$paddingMd: calc((#{$heightMd} - #{$fontSizeMd} - (#{$borderSizeMd} * 2)) / 2);

.button {
  border: $borderSize solid #00B6ED;
  color: #00B6ED;
  min-width: 184px;
  line-height: $fontSize; //prevent browser calculation
  letter-spacing: 0.2em;
  font-size: $fontSize;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: $padding;
  cursor: pointer;
  @include md() {
    min-width: 400px;
    max-width: 400px;
    border: $borderSizeMd solid #00B6ED;
    line-height: $fontSizeMd; //prevent browser calculation
    font-size: $fontSizeMd;
    padding: $paddingMd;
  }
}

.button:hover {
  text-decoration: none;
  color: #FFFFFF;
  background-color: #00B6ED;
}

.button-dark {
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.button-dark:hover {
  color: #043341;
  background-color: #FFFFFF;
}
