footer {
  color: #FFFFFF;
  background-color: #043341;
  text-align: right;
  font-weight: 400;
  padding: 8px;
  font-size: 0.9rem;
  line-height: 1.25rem;
  @include md() {
    padding: 16px;
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .link {
    color: inherit;
  }

  img {
    width: 24px;
    @include md() {
      width: 80px;
    }
  }

  div {
    > img {
      margin-left: 0;
      margin-right: 8px;
    }
    display: flex;
    align-items: center;
    @include md() {
      justify-content: space-around;
      align-items: flex-start;
        > span {
          margin-left: 8px;
          margin-right: 0;
        }
    }
  }

  .button {
    min-width: 310px;
  }
}

footer * {
  margin: 8px 0;
  @include md() {
    margin: 0 8px;
  }
}
